function CardWhyOaasis({image_url, title, details}){
    return(
        <div class="transition-all flex-col mb-10 w-[370px] h-[500px] bg-white shadow-[0_3px_8px_0_rgba(0,0,0,0.1)] group overflow-hidden
         hover:shadow-[0_10px_10px_0_rgba(0,0,0,0.2)]">
            <img src={image_url} class="transition-all pt-3 pl-3 pr-3 w-full h-72 rounded-t-[20px] object-cover object-center" />
            <div class="pr-5 pl-5 pb-5">
                <p class="font-MerriweatherSans font-m text-[22px] text-black mt-3">{title}</p>
                <p class="font-MerriweatherSans font-l text-[14px] text-start mt-2 text-[#6D6D6D]">{details}</p>
            </div>
        </div>
    );
}

export default CardWhyOaasis;