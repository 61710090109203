function ApiService(){
    return(
        <div class="items-center w-full pr-[5%] pl-[5%]">
            <div class="grid grid-cols-2 mt-20 mb-28 w-full xl:w-[1280px] mx-auto">
                <div class="font-MerriweatherSans font-l text-[36px]">
                    OaaSIS API Services
                </div>
                <div class="font-MerriweatherSans font-l text-[18px]">
                    <div class="mb-5">
                        Our advanced <span class="text-omelet_purple">AI-based solver</span> delivers <span class="text-omelet_purple">unparalleled</span> 
                        efficiency for middle-mile logistics, last-mile deliveries, food delivery, and quick commerce. 
                    </div>
                    <div>
                        Transform your delivery operations and <span class="text-omelet_purple">elevate your business performance</span> with our cutting-edge technology.
                    </div>
                </div>
                
            </div>
            <hr class="border-gray-200" />
        </div>
    );
}

export default ApiService;