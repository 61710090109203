import Button from './ui/button';

import icon from '../assets/images/banner_icon.png';

function Banner(){

    const api_doc_button=(
        <Button className="group relative transition-all w-[210px] h-[44px] bg-white text-omelet_purple text-[15px] flex items-center justify-center
        hover:bg-omelet_violet hover:border-omelet_violet hover:text-white border border-omelet_purple"
        onClick={()=>window.location.href="https://routing.oaasis.cc/developers/doc/introduction/omelet_approach.html"}>
        <span 
        class="transition-transform duration-300 transform group-hover:-translate-x-5">
            Documentation
        </span>
        <span 
            class="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
            →
        </span>
        </Button>
    );
    const get_api_button=(
        <Button className="group relative transition-all w-[210px] h-[44px] bg-omelet_purple text-white text-[15px] flex items-center justify-center 
        hover:bg-omelet_violet hover:border-omelet_violet hover:text-white border border-omelet_purple"
        onClick={()=>window.location.href="https://routing.oaasis.cc/developers/"}>
        <span
        class="transition-transform duration-300 transform group-hover:-translate-x-5">
            Get API Key
        </span>
        <span 
            class="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
            →
        </span>
        </Button>
    );

    return(
        <div class="items-center bg-[#FAFAFA] w-full pt-[1%] pb-[2%] pr-[5%] pl-[5%]">
            <div class="grid md:grid-cols-banner1 w-full xl:w-[1280px] mx-auto">
                <div class="flex flex-col">
                    <p class="text-[38pt] text-black pt-[14%] mb-10 text-left font-MerriweatherSans font-m leading-tight">
                        AI-Powered Route Optimization <span class='text-omelet_purple'> API</span>
                    </p>
                    <p class="text-black mr-[28%] 2xl:mr-[30%] text-justify mb-14 text-[16px] font-MerriweatherSans font-l">
                        Supercharge your logistics with lightning-fast,<br />
                        high-performance routing solutions.
                    </p>
                    <div class="flex space-x-3 pb-8">
                        {api_doc_button}
                        {get_api_button}
                    </div>
                </div>
                <div class="flex items-center justify-center">
                    <img src={icon} class="w-full xl:mt-0"/>
                </div>
            </div>
            
        </div>
    );
}

export default Banner;