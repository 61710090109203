import React from 'react';
import { BrowserRouter, Routes, Route, Link, useNavigate } from "react-router-dom";

import Button from './ui/button';

import logo from '../assets/images/omelet_icon_gradient.png';
import textlogo from '../assets/images/OaaSIS.png';

function Header() {


    return (
        <div class="flex items-center justify-between p-2 sticky top-0 drop-shadow bg-white z-50 w-full">
            <a href="/" class="flex items-center">
                <img src={logo} alt="logo" class="ml-3 mr-2 hidden sm:inline w-[32px] h-[32px]" />
                <img src={textlogo} alt="OaaSIS" class="ml-3 mr-5 hidden md:inline w-[86px] h-auto" />
                <span class="self-center font-MerriweatherSans font-l text-[16px] whitespace-nowrap text-[#808080] hidden lg:inline">Routing Engine</span>
            </a>
            <nav class="flex items-center space-x-5 sm:space-x-12 mr-3 font-MerriweatherSans font-l text-[14px]">
                {/* <Link onClick={() => window.location.href = "https://dev.routing.oaasis.cc/developers/profile"} class="relative text-sm text-gray1 group w-max">
                    My Page
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link> */}
                <Link onClick={() => window.location.href = "https://routing.oaasis.cc/developers/doc/introduction/omelet_approach.html"} class="relative text-sm text-gray1 group w-max">
                    Documentation
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
                <Link to="/dummy" class="relative text-sm text-gray1 group w-max">
                    Pricing
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
                <Link onClick={() => window.location.href = "https://omelet.ai/"} class="relative text-sm text-gray1 group w-max">
                    Company
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
                <Link onClick={() => window.location.href = "https://omelet.ai/contact-us"} class="relative text-sm text-gray1 group w-max">
                    Contact
                    <span class="absolute -bottom-1 left-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                    <span class="absolute -bottom-1 right-1/2 w-0 transition-all h-0.5 bg-omelet_purple group-hover:w-3/6"></span>
                </Link>
            </nav>

        </div>
    );
}

export default Header;