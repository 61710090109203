import CardWhyOaasis from "./ui/card_why_oaasis";

import uf from '../assets/images/uf.jpg';
import pf from '../assets/images/pf.jpg';
import user from '../assets/images/user.jpg';

function WhyOaasis(){
    return(
        <div class="items-center w-full pt-[2%] pb-[2%] pr-[5%] pl-[5%]">
            <div class="w-full font-MerriweatherSans xl:w-[1280px] mx-auto">
                <div class="flex flex-col font-l text-[36px]">
                    <p>Why OaaSIS Routing Engine?</p>
                </div>
                <div class="flex flex-col items-center justify-center mb-16">
                    <div class="grid lg:grid-cols-3 w-full xl:w-[1280px] mt-16">
                        <div class="mx-auto">
                            <CardWhyOaasis
                            image_url={uf}
                            title="Ultimate Flexibility"
                            details="Our optimization solver allows you to minimize total travel distance, reduce task completion time,
                            or cut down delivery time to customers, tailored to various business goals. Enjoy extreme flexibility with 
                            customizable constraints like time windows, capacity, and delivery area preferences." />
                        </div>
                        <div class="mx-auto">
                            <CardWhyOaasis
                            image_url={pf}
                            title="Powerful Performance"
                            details="Built with generative AI-based learning, our routing engine tackles larger problems faster and 
                            more accurately than ever before. Solve enormous logistical challenges at unprecedented speeds, making it 
                            ideal for real-world industrial applications." />
                        </div>
                        <div class="mx-auto">
                            <CardWhyOaasis
                            image_url={user}
                            title="User-friendly Interface"
                            details="Our API is designed with developers in mind, featuring intuitive input and output definitions.
                            Easily integrate and deploy our solution with minimal effort, ensuring a smooth and efficient user
                            experience." />
                        </div>
                    </div>
                </div>
            </div>
            <hr class="border-gray-200" />
        </div>
    );
}

export default WhyOaasis;