import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import './index.css';

import Main from './main';
import Dummy from './page/dummy';

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename='/routing'>
      <Routes>
        <Route path='/' element={<Main />} />
        <Route path="/dummy" element={<Dummy />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
