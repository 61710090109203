import how_img from '../assets/images/how_img.png';

function HowOaasis(){
    return(
        <div class="items-center w-full pt-[1%] pb-[2%] pr-[5%] pl-[5%]">
            <div class="w-full font-MerriweatherSans xl:w-[1280px] mx-auto">
                <div class="flex flex-col font-l text-[36px]">
                    <p>How OaaSIS Routing Engine Solves<br /> Routing Problems Effectively?</p>
                </div>
                <div class='mt-10 mb-14'>
                    <img src={how_img} class=" md:mt-40 xl:mt-0 w-[1200px] h-[684px]" />
                </div>
            </div>
        </div>
    );
}

export default HowOaasis;