import Button from "./ui/button";

function Footer(){

    const get_started_btn = (
        <Button className="group relative transition-all w-[200px] bg-[#1F1F1F] border border-white text-[15px]
        hover:bg-[#1F1F1F] mt-14">
            <span 
                className="transition-transform duration-300 transform group-hover:-translate-x-5">
                Get started
            </span>
            <span 
                className="absolute right-4 transition-opacity duration-300 opacity-0 group-hover:opacity-100">
                →
            </span>
        </Button>
    );

    return(
        <footer class="bg-[#1F1F1F] text-white font-MerriweatherSans py-12 w-full">
            <div class="xl:w-[1280px] mx-auto">
                <div class="grid grid-cols-2 mb-32 mt-10 mx-5">
                    <div class="font-m text-[42px] justify-center">
                        Get Started Now<br /> with the Free Version!
                    </div>
                    <div>
                        <div class="font-l text-[16px]">
                            Take the first step towards optimizing your logistics.
                            Sign up today and see the difference our AI-driven solutions can make for your business.
                        </div>
                    {get_started_btn}
                    </div>
                </div>
                <hr class="border-gray-400 mb-8 mx-5" />
                <div class="grid grid-cols-2 text-gray-400 mx-5">
                    <div class="font-l text-[15px]">For urgent responses, Just drop us email –{" "}
                        <a href="mailto:info@omelet.ai" class="underline">
                            info@omelet.ai
                        </a>
                    </div>
                    <div class="font-l text-[15px] text-right">Copyright © 2024 by developers.oaasis.cc</div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;