import React from 'react';

function Button({ onClick, children, className }) {
  return React.createElement(
    'button',
    {
      onClick: onClick,
      className: `py-2 px-4 transition ${className}`,
    },
    children
  );
}

export default Button;
