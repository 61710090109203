import Header from './components/header';
import Banner from './components/banner';
import ApiService from './components/apiservice';
import WhyOaasis from './components/why_oaasis';
import HowOaasis from './components/how_oaasis';
import Features from './components/features';
import GetStarted from './components/get_started';
import Footer from './components/footer';

function Main() {
    return (
        <div class="flex flex-col items-center min-h-screen bg-white font-NotoSans">
            <Header />
            <Banner />
            <div class="w-full">
                <ApiService />
                <WhyOaasis />
                <HowOaasis />
            </div>
            <Footer />
        </div>
    );
}

export default Main;
